import { Fragment } from "react";
import { useEffect } from "react";
import { Menu, Transition } from "@headlessui/react";
import { Bars3BottomLeftIcon } from "@heroicons/react/24/outline";
import { IAuthStatusContext, useAuthStatus } from "../../context/AuthContext";
import { useGlobal } from "../../context/GlobalContext";
import { signOut } from "firebase/auth";
import { auth } from "../../firebase.config";
import { Link } from "react-router-dom";

import { toast } from "react-toastify";
import { getErrorMessage } from "../../inc/helpers";

import BlankUserAvatar from "../../assets/images/lorem-avatar.jpg";
import { classNames } from "../../utils/utils";

const Header = ({ setMobileMenuOpen }: any) => {
  const {
    state: { pageTitle, userAvatar },
    dispatch,
  } = useGlobal();

  const { user } = useAuthStatus() as IAuthStatusContext;

  useEffect(() => {
    dispatch({
      type: "SET_USER_AVATAR",
      payload: user.photoURL,
    });
  }, [user, dispatch]);

  const handleLogout = async () => {
    try {
      await signOut(auth);
    } catch (error:any) {
      toast.error(getErrorMessage(error));
      console.log(error);
    }
  };
  return (
    <>
      <div className="relative top-0 z-10 flex h-16 md:h-28 flex-shrink-0 bg-white shadow-md md:shadow-none">
        <button
          type="button"
          className="border-r border-gray-200 px-4 text-gray-500 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-blue-500 md:hidden"
          onClick={() => setMobileMenuOpen(true)}
        >
          <span className="sr-only">Open sidebar</span>
          <Bars3BottomLeftIcon className="h-6 w-6" aria-hidden="true" />
        </button>
        <div className="flex-1 flex justify-between mx-12 sm:mx-16 border-none md:border-solid border-b border-gray-300">
          <div className="flex-1 flex justify-between items-center">
            <div className="flex-1 flex">
              <h1
                className={
                  "hidden md:block font-medium text-sm sm:text-md md:text-xl lg:text-3xl text-gray-800 mb-0"
                }
              >
                {pageTitle}
              </h1>
            </div>
          </div>
          <Menu as="div" className="flex items-center space-x-4 sm:space-x-6">
            <div className="flex justify-between items-center space-x-3 ">
              <Menu.Button className="text-gray-700 font-medium text-base">
                Hi, {user?.displayName || "User"}
              </Menu.Button>
              <div className="relative ml-3">
                <div>
                  <Menu.Button className="flex max-w-xs items-center rounded-full bg-white text-sm focus:outline-none focus:ring-2 focus:ring-indigo-500 focus:ring-offset-2">
                    <span className="sr-only">Open user menu</span>
                    <img
                      className="h-10 w-10 rounded-full object-cover"
                      src={userAvatar ? userAvatar : BlankUserAvatar}
                      alt=""
                    />
                  </Menu.Button>
                </div>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2 w-48 origin-top-right rounded-md bg-white py-1 shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                    <div className="px-4 py-4">
                      <p className="text-sm my-0">Signed in as</p>
                      <p className="text-sm font-medium text-primary truncate my-0">
                        {user?.email}
                      </p>
                    </div>

                    <Menu.Item>
                      {({ active }) => (
                        <Link
                          to="/settings"
                          className={`block px-4 py-2 text-sm font-medium text-gray-700 ${
                            active ? "bg-lighter-gray" : ""
                          }`}
                        >
                          Settings
                        </Link>
                      )}
                    </Menu.Item>
                    <Menu.Item>
                      {({ active }) => (
                        <button
                          onClick={handleLogout}
                          className={classNames(
                            active ? "bg-lighter-gray" : "",
                            " px-4 w-full flex items-start py-2 text-sm font-medium text-red-700"
                          )}
                        >
                          Sign out
                        </button>
                      )}
                    </Menu.Item>
                  </Menu.Items>
                </Transition>
              </div>
            </div>
          </Menu>
        </div>
      </div>
    </>
  );
};

export default Header;
