import { Link } from "react-router-dom"
import Logo from "../assets/images/allegro-logo.png"

export default function NotFound() {
    return (
      <>
        {/*
          This example requires updating your template:
  
          ```
          <html class="h-full">
          <body class="h-full">
          ```
        */}
        <div className="flex min-h-full flex-col bg-gray-900 pb-12 pt-16">
          <main className="mx-auto flex w-full max-w-7xl flex-grow flex-col justify-center px-6 lg:px-8">
            <div className="flex flex-shrink-0 justify-center">
              <Link to="/" className="inline-flex">
                <span className="sr-only">Allegro Classics Conservatory</span>
                <img
                  className="h-16 w-auto"
                  src={Logo}
                  alt=""
                />
              </Link>
            </div>
            <div className="py-16">
              <div className="text-center">
                <p className="text-xl font-semibold text-red-600">404</p>
                <h1 className="mt-2 text-4xl font-bold tracking-tight text-gray-100 sm:text-5xl">Page not found.</h1>
                <p className="mt-2 text-base text-gray-400">Sorry, we couldn’t find the page you’re looking for.</p>
                <div className="mt-6">
                  <Link to="/" className="text-base font-medium text-primary hover:text-primary-hover">
                    Go back home
                    <span aria-hidden="true"> &rarr;</span>
                  </Link>
                </div>
              </div>
            </div>
          </main>
          <footer className="mx-auto w-full max-w-7xl flex-shrink-0 px-6 lg:px-8">
            <nav className="flex justify-center space-x-4">
              <a href="#" className="text-sm font-medium text-gray-400 hover:text-gray-500">
                Contact Support
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="#" className="text-sm font-medium text-gray-400 hover:text-gray-500">
                Status
              </a>
              <span className="inline-block border-l border-gray-300" aria-hidden="true" />
              <a href="#" className="text-sm font-medium text-gray-400 hover:text-gray-500">
                Twitter
              </a>
            </nav>
          </footer>
        </div>
      </>
    )
  }
  