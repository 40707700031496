import { useState, useEffect } from "react";
import { useOutlet } from "react-router-dom";
import { useGlobal } from "../../context/GlobalContext";
import { IAuthStatusContext, useAuthStatus } from "../../context/AuthContext";
import Sidebar from "../Sidebar/Sidebar";
import Header from "../Header/Header";

import { db } from "../../firebase.config";
import { doc, onSnapshot } from "firebase/firestore";

function Dashboard() {
  const outlet = useOutlet();
  const { state: {pageTitle}, dispatch } = useGlobal();
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const [showSlideOver, setShowSlideOver] = useState(false);

  const { claims } = useAuthStatus() as IAuthStatusContext;
  const isPro = claims?.role === "pro";

  useEffect(() => {
    const unsub = onSnapshot(doc(db, "stats", "counts"), (doc) => {
      dispatch({
        type: "SET_COUNTS",
        payload: doc.data(),
      });
    });

    return unsub;
  }, [dispatch]);

  return (
    <div className="relative h-screen flex overflow-hidden bg-gray-100">
      <Sidebar />
      <div className="flex-1 flex flex-col h-screen overflow-hidden">
        <Header setMobileMenuOpen={setSidebarOpen} />
        <div className="w-full h-full px-8 pb-8 sm:px-12 sm:pb-12 md:px-16 md:pb-16 overflow-auto">
          <div className="pt-8 block md:hidden">
            <h1 className="font-medium text-2xl text-primary mb-0">
              {pageTitle}
            </h1>
          </div>
          <main className="py-8">{outlet}</main>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
