import { FlagSpinner } from "react-spinners-kit";

const WebPageLoader = () => {
  return (
    <div className="h-screen w-screen z-100 bg-gray-900 flex justify-center items-center">
        <FlagSpinner color={"#1172b9"} size={60} loading={true} />
    </div>
  );
};

export default WebPageLoader;
