import moment from 'moment';

import { levels, programmes, classTypes, categories, grades, venues, modesOfStudy } from "./data";

export const getErrorMessage = (error: { code: string; message: any; }) => {
    if (error.code === 'auth/email-already-exists')
        return 'The provided email is already in use by an existing user';
    if (error.code === 'auth/email-already-in-use')
        return 'The provided email is already in use by an existing user';
    if (error.code === 'auth/invalid-email')
        return 'The provided email is invalid';
    if (error.code === 'auth/invalid-password')
        return 'The provided password is invalid';
    if (error.code === 'auth/invalid-phone-number')
        return 'The provided phone is invalid';
    if (error.code === 'auth/phone-number-already-exists')
        return 'The provided phone number is already in use by an existing user';
    if (error.code === 'auth/user-not-found')
        return 'There is no user with the provided email';
    if (error.code === 'auth/wrong-password') return 'Wrong email or password';
    if (error.code === 'auth/too-many-requests')
        return 'Access to this account has been temporarily disabled due to many failed login attempts. You can immediately restore it by resetting your password or you can try again later. ';
    if (error.code === 'auth/popup-closed-by-user')
        return 'Google Authentication cancelled';
    if (error.code === 'auth/requires-recent-login') return 'Problem with auth';

    if (error.code === 'storage/unknown') return 'An unknown error occurred.';
    if (error.code === 'storage/object-not-found')
        return 'No object exists at the desired reference.';
    if (error.code === 'storage/unauthenticated')
        return 'User is unauthenticated, please authenticate and try again';
    if (error.code === 'storage/unauthorized')
        return 'User is not authorized to perform the desired action, check your security rules to ensure they are correct.';
    if (error.code === 'storage/retry-limit-exceeded')
        return 'The maximum time limit on an operation (upload, download, delete, etc.) has been excceded. Try uploading again.';
    if (error.code === 'storage/canceled') return 'Operation cancelled.';
    if (error.code === 'storage/server-file-wrong-size')
        return 'File on the client does not match the size of the file recieved by the server. Try uploading again.';

    return error.message ? error.message : error;
};

/*
 * GET DOMAIN
 */
export const getDomain = () => {
    if (process.env.REACT_APP_DEPLOY_ENV === 'development')
        return 'http://localhost:3000';
    if (process.env.REACT_APP_DEPLOY_ENV === 'staging')
        return 'https://allegrocc.com';
    if (process.env.REACT_APP_DEPLOY_ENV === 'production')
        return 'https://allegrocc.com';
};

/*
 * GET NAME OF ENTITY
 */
type TEntity = 'instruments' | 'levels' | 'programmes' | 'categories' | 'grades' | 'venues' | 'classTypes' | 'modesOfStudy';
export const getNameOfEntity = (entity: TEntity, id:string) => {
    switch (entity) {
        case 'instruments':
            return id?.charAt(0).toUpperCase() + id?.slice(1)
        case 'levels':
            return levels.find((level) => level.value === id)?.label;
        case 'programmes':
            return programmes.find((programme) => programme.value === id)?.label;
        case 'categories':
            return categories.find((category) => category.value === id)?.label;
        case 'grades':
            return grades.find((grade) => grade.value === id)?.label;
        case 'venues':
            return venues.find((venue) => venue.value === id)?.label;
        case 'classTypes':
            return classTypes.find((classType) => classType.value === id)?.label;
        case 'modesOfStudy':
            return modesOfStudy.find((modeOfStudy) => modeOfStudy.value === id)?.label;
        default:
            return '';
    }
}

export const utcToDate = (str:string)=> {
    if (!str) return '';

    const dateObject = moment.utc(str);
    return dateObject.format('MMM DD, YYYY');
};

export const numberToUsd = (number:number) =>
    new Intl.NumberFormat('gh-GH', {
        style: 'currency',
        currency: 'GHS',
        minimumFractionDigits: 2,
    }).format(Number(number));