import {
  AcademicCapIcon,
  MusicalNoteIcon,
  UserGroupIcon,
} from "@heroicons/react/20/solid";

import { useRef } from "react";
import useIntersectionObserver from "../../../components/hooks/useIntersectionObserver";

const features = [
  {
    name: "Structured music education",
    icon: AcademicCapIcon,
  },
  {
    name: "Dynamic music performances",
    icon: MusicalNoteIcon,
  },
  {
    name: "Driving societal impact (through our Music Foundation - Allegro Classics Foundation)",
    icon: UserGroupIcon,
  },
];

export default function HelpUs() {

  return (
    <div className="overflow-hidden bg-white py-24 sm:pb-32 sm:pt-20">
      <div className="mx-auto max-w-7xl md:px-6 lg:px-8 opacity-0 fade-in">
        <div className="grid grid-cols-1 gap-x-8 gap-y-16 sm:gap-y-20 lg:grid-cols-2 lg:items-start">
          <div className="px-6 md:px-0 lg:pr-4 lg:pt-4">
            <div className="mx-auto max-w-2xl lg:mx-0 lg:max-w-lg">
              <p className="mt-2 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                Help us make the next 5 years more Impactful
              </p>
              <p className="mt-4 text-lg leading-8 text-gray-600">
                When you pledge a <span className="font-semibold text-black">donation</span> to Allegro Classics
                Conservatory, you are making it possible for us to actively
                drive our Mission of "inspiring and reviving the intereset in
                quality music" through:
              </p>
              <dl className="mt-6 max-w-xl space-y-4 text-base leading-7 text-gray-600 lg:max-w-none">
                {features.map((feature) => (
                  <div key={feature.name} className="relative pl-9">
                    <dt className="inline font-medium text-gray-900">
                      <feature.icon
                        className="absolute left-1 top-1 h-5 w-5 text-primary"
                        aria-hidden="true"
                      />
                      {feature.name}
                    </dt>{" "}
                  </div>
                ))}
              </dl>
            </div>
          </div>
          <div className="sm:px-6 lg:px-0">
            <img
              src="https://images.pexels.com/photos/6348119/pexels-photo-6348119.jpeg?auto=compress&cs=tinysrgb&w=1260&h=750&dpr=2"
              alt=""
              className="w-full h-[450px] rounded-lg shadow-xl object-cover object-center"
            />
          </div>
        </div>
      </div>
    </div>
  );
}
