import React, { Fragment, useState } from "react";
import { Dialog, Transition } from "@headlessui/react";
import { AdminNav } from "../../navigation";
import Logo from "../../assets/images/allegro-logo.png";
import { XMarkIcon, Bars3Icon } from "@heroicons/react/24/outline";
import { scrollbarClassesDark } from "../../inc/utils";
import { useAuthStatus } from "../../context/AuthContext";
import { useNavigate, NavLink } from "react-router-dom";
import { classNames } from "../../utils/utils";

import { IAuthStatusContext } from "../../context/AuthContext";

import { scrollbarClasses } from "../../inc/utils";

const Sidebar = () => {
  const { claims }  = useAuthStatus() as IAuthStatusContext;
  const [sidebarOpen, setSidebarOpen] = useState(false);
  const navigate = useNavigate();

  const openPage = (href: any) => {
    setSidebarOpen(false);
    navigate(href);
  };
  return (
    <>
      <div>
        <Transition.Root show={sidebarOpen} as={Fragment}>
          <Dialog
            as="div"
            className="relative z-50 lg:hidden"
            onClose={setSidebarOpen}
          >
            <Transition.Child
              as={Fragment}
              enter="transition-opacity ease-linear duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="transition-opacity ease-linear duration-300"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-gray-900/80" />
            </Transition.Child>

            <div className="fixed inset-0 flex">
              <Transition.Child
                as={Fragment}
                enter="transition ease-in-out duration-300 transform"
                enterFrom="-translate-x-full"
                enterTo="translate-x-0"
                leave="transition ease-in-out duration-300 transform"
                leaveFrom="translate-x-0"
                leaveTo="-translate-x-full"
              >
                <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-in-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in-out duration-300"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                  >
                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                      <button
                        type="button"
                        className="-m-2.5 p-2.5"
                        onClick={() => setSidebarOpen(false)}
                      >
                        <span className="sr-only">Close sidebar</span>
                        <XMarkIcon
                          className="h-6 w-6 text-white"
                          aria-hidden="true"
                        />
                      </button>
                    </div>
                  </Transition.Child>
                  {/* Sidebar component, swap this element with another sidebar if you like */}
                  <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 pb-2 ring-1 ring-white/10">
                    <div className="flex h-16 shrink-0 items-center">
                      <img
                        className="h-12 w-auto"
                        src={Logo}
                        alt="Your Company"
                      />
                    </div>
                    <nav className="flex flex-1 flex-col">
                      <ul className="flex flex-1 flex-col gap-y-7">
                        <li>
                          <ul className="-mx-2 space-y-1">
                            {AdminNav?.map((item, itemIdx) => (
                              <li key={itemIdx}>
                                <NavLink
                                  to={item.url}
                                  className={({ isActive }) =>
                                    classNames(
                                      isActive
                                        ? "bg-gray-800 text-white"
                                        : "text-gray-100 hover:text-white hover:bg-gray-800",
                                      "group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold"
                                    )
                                  }
                                >
                                  {item?.icon && (
                                    <item.icon
                                      className="h-6 w-6 shrink-0"
                                      aria-hidden="true"
                                    />
                                  )}
                                  {item.title}
                                </NavLink>
                              </li>
                            ))}
                          </ul>
                        </li>
                      </ul>
                    </nav>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </Dialog>
        </Transition.Root>

        {/* Static sidebar for desktop */}
        <div className="hidden md:inset-y-0 md:z-50 md:flex md:h-full md:w-72 md:flex-col">
          {/* Sidebar component, swap this element with another sidebar if you like */}
          <div className={`flex grow flex-col gap-y-5 overflow-y-auto bg-primary px-6 ${scrollbarClassesDark}`}>
            <div className="flex h-16 shrink-0 items-center pt-4">
              <img
                className="h-20 w-auto"
                src={Logo}
                alt="Your Company"
              />
            </div>
            <nav className="flex flex-1 flex-col mt-5">
              <ul className="flex flex-1 flex-col gap-y-7">
                <li>
                  <ul className="-mx-2 space-y-1">
                    {AdminNav.map((item, itemIdx) => (
                      <li key={itemIdx}>
                        <NavLink
                          to={item?.url}
                          className={({ isActive }) =>
                            classNames(
                              isActive
                                ? "bg-gray-800 text-white"
                                : "text-gray-100 hover:text-white hover:bg-gray-800",
                              "group flex gap-x-3 rounded-md p-2 text-base leading-6 font-medium"
                            )
                          }
                        >
                          {item?.icon && (
                            <item.icon
                              className="h-6 w-6 shrink-0"
                              aria-hidden="true"
                            />
                          )}
                          {item?.title}
                        </NavLink>
                      </li>
                    ))}
                  </ul>
                </li>
              </ul>
            </nav>
          </div>
        </div>
      </div>
    </>
  );
};
export default Sidebar;
