import React, { createContext, useContext, useReducer, ReactNode } from "react";
import globalReducer from "./GlobalReducer";

// Define the shape of your initial state
interface GlobalState {
  pageTitle: string;
  backLink: string;
  userAvatar: string;
}

const GlobalContext = createContext<{
  state: GlobalState;
  dispatch: React.Dispatch<any>;
} | undefined>(undefined);

export const GlobalProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  // const queryURL = useQuery();

  const initialState: GlobalState = {
    pageTitle: "",
    backLink: "",
    userAvatar: "",
  };
  const [state, dispatch] = useReducer(globalReducer, initialState);

  return (
    <GlobalContext.Provider
      value={{
        state,
        dispatch,
      }}
    >
      {children}
    </GlobalContext.Provider>
  );
};

export const useGlobal = () => {
  const context = useContext(GlobalContext);
  if (context === undefined) {
    throw new Error("useGlobal must be used within a GlobalProvider");
  }
  return context;
};

export default GlobalContext;
