import { INavigationItem } from "./types"
import { lazy } from "react"
import { ChartBarIcon, BookOpenIcon, UserCircleIcon, UsersIcon, MusicalNoteIcon } from "@heroicons/react/24/outline"
import { FiCalendar } from "react-icons/fi"

const Overview = lazy(() => import("../pages/Admin/Overview/Overview"))
const MusicStudy = lazy(() => import("../pages/Admin/MusicStudy/MusicStudy"))
const Registrations = lazy(() => import("../pages/Admin/Registrations/Registrations"))

const adminNav:INavigationItem[] = [
    {
        title: 'Overview',
        url: 'overview',
        component: Overview,
        icon: ChartBarIcon,
    },
    {
        title: 'Registrations',
        url: 'registrations',
        component: Registrations,
        icon: BookOpenIcon,
    },
    {
        title: 'Students',
        url: 'students',
        component: undefined,
        icon: UsersIcon,
    },
    {
        title: 'Staff',
        url: 'staff',
        component: undefined,
        icon: UserCircleIcon,
    },
    {
        title: 'Music Study',
        url: 'music-study',
        component: MusicStudy,
        icon: MusicalNoteIcon,
    },
    {
        title: 'Schedule',
        url: 'schedule',
        component: undefined,
        icon: FiCalendar,
    }
]

export default adminNav
