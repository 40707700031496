import { CircleSpinner } from 'react-spinners-kit';

const ButtonLoader = ({ className='', color='#fff' }) => {
    const getColor = (color) => {
        switch (color) {
            case 'primary':
                return '#fff';
            case 'secondary':
                return '#43bee5';
            default:
                return '#fff';
        }
    };
    return (
        <div className={className}>
            <CircleSpinner color={getColor(color)} size={20} loading={true} />
        </div>
    );
};

export default ButtonLoader;
