import { Navigate, Outlet } from 'react-router-dom';
import { useAuthStatus } from './context/AuthContext';
import { FC } from 'react';
import { IAuthStatusContext } from './context/AuthContext';

interface IPrivateRoute {
    children?: any;
    shouldBeLoggedIn?: boolean;
    redirectTo?: string;
    roles?: string[];
    status?: string[];
    auth?: boolean;
}

const PrivateRoute: FC<IPrivateRoute> = ({
    children,
    shouldBeLoggedIn = true,
    redirectTo = 'login',
    auth = false,
    roles,
    status,
  }) => {
    const { claims, isLoggedIn, checkingStatus } = useAuthStatus() as IAuthStatusContext;
  
    if (checkingStatus) return null;
    console.log(auth)
  
    if (!shouldBeLoggedIn && isLoggedIn && auth) {
      // Redirect to the admin dashboard if the user is already logged in
      return <Navigate to={redirectTo} replace />;
    }
  
    if (shouldBeLoggedIn && !isLoggedIn) {
      console.log('Redirecting to', redirectTo);
      return <Navigate to={redirectTo} replace />;
    }
  
    return children ? children : <Outlet />;
  };
  

export default PrivateRoute;
