import { useEffect, useRef } from 'react';

interface ObserverConfig {
  targetRef: React.RefObject<HTMLElement>;
  className: string;
  options: IntersectionObserverInit;
}

const useIntersectionObserver = (configs: ObserverConfig[]): void => {
  useEffect(() => {
    const observers: IntersectionObserver[] = [];

    configs.forEach((config) => {
      const { targetRef, className, options } = config;
      const observer = new IntersectionObserver(
        (entries) => {
          entries.forEach((entry) => {
            if (entry.isIntersecting) {
              entry.target.classList.add(className);
            } 
            // else {
            //   entry.target.classList.remove(className); // Optional: Remove class when not intersecting
            // }
          });
        },
        options
      );

      const targetElement = targetRef.current;
      if (targetElement) {
        observer.observe(targetElement);
        observers.push(observer);
      }
    });

    return () => {
      observers.forEach((observer) => {
        observer.disconnect();
      });
    };
  }, [configs]);
};

export default useIntersectionObserver;
