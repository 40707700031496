import React, { useEffect, useContext, createContext, useState, ReactNode } from 'react';
import { useNavigate } from 'react-router-dom';
import { GrClose } from 'react-icons/gr';

type ModalProps = {
    children: ReactNode;
    id: string;
    closeLink?: string | null;
    onClose?: () => void | null;
    isBig?: boolean;
    required?: boolean;
    dark?: boolean;
};

type ModalContextType = {
    open: string;
    setOpen: React.Dispatch<React.SetStateAction<string>>;
};

const ModalContext = createContext<ModalContextType | undefined>(undefined);

export const ModalProvider: React.FC<any> = ({ children }) => {
    const [open, setOpen] = useState<string>('');

    return (
        <ModalContext.Provider
            value={{
                open,
                setOpen,
            }}
        >
            {children}
        </ModalContext.Provider>
    );
};

export const useModal = (): ModalContextType => {
    const context = useContext(ModalContext);
    if (context === undefined) {
        throw new Error('useModal must be used within a ModalProvider');
    }
    return context;
};

export const Modal: React.FC<ModalProps> = ({
    children,
    id,
    closeLink = null,
    onClose = null,
    isBig = false,
    required = false,
    dark = false,
}) => {
    const { open, setOpen } = useModal();
    const [active, setActive] = useState<boolean>(false);
    const navigate = useNavigate();

    useEffect(() => {
        setActive(open === id);
    }, [open, id]);

    useEffect(() => {
        document.querySelector('body')!.style.overflow = active ? 'hidden' : 'auto';
    }, [active]);

    const close = () => {
        if (required) return;
        setOpen('');
        if (onClose && typeof onClose === 'function') onClose();
        if (closeLink) navigate(closeLink);
    };

    return (
        <div className="overflow-hidden h-0" id={id}>
            <div
                className={`fixed w-full h-full inset-0 ${dark ? 'bg-primary/95' : 'bg-black/30'} cursor-pointer transition duration-300 z-30 ${
                    active
                        ? 'opacity-100 backdrop-blur-sm backdrop-opacity-100 visible'
                        : 'opacity-0 backdrop-blur-0 backdrop-opacity-0 invisible'
                }`}
                onClick={close}
            ></div>
            <div
                className={`fixed top-1/2 left-1/2 rounded-lg z-[100] bg-white overflow-auto  transition duration-300 -translate-x-1/2 -translate-y-1/2 py-10 px-8 ${
                    isBig
                        ? 'w-screen h-screen lg:max-h-[95vh] lg:w-[90vw] lg:h-auto'
                        : 'w-[90%] max-h-[90vh] max-w-[660px] h-auto min-w-[400px]'
                } ${
                    active ? 'opacity-100 visible' : 'opacity-0 invisible'
                }`}
            >
                {children}
                {!required && (
                    <button
                        title='Close'
                        className="absolute top-4 right-4 cursor-pointer z-50"
                        type="button"
                        onClick={close}
                    >
                        <GrClose className="w-6 h-6" />
                    </button>
                )}
            </div>
        </div>
    );
};

export default Modal;
