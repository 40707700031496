import {
  FiBook,
  FiBookOpen,
  FiCloudLightning,
  FiMusic,
  FiUser,
  FiUserCheck,
  FiUsers,
  FiAward,
} from "react-icons/fi";

const Frederick = require("../assets/images/frederick.jpeg");
const Samuel = require("../assets/images/samuel.jpeg");
const Kingsley = require("../assets/images/kingsley.jpeg");
const Jeremy = require("../assets/images/jeremy.jpeg");
const Elikplim = require("../assets/images/elikplim.jpeg");
const Joseph = require("../assets/images/joseph.jpeg");
const Joseline = require("../assets/images/joseline.jpeg");
const Isabella = require("../assets/images/isabella.jpeg");
const Heather = require("../assets/images/heather.jpeg");
const Larmour = require("../assets/images/larmour.jpeg");
const Ruth = require("../assets/images/ruth.jpeg");
// const Bless = require("../../assets/images/bless.jpeg");
// const Darell = require("../../assets/images/darell.jpeg");

export const CoreTeam = [
  {
    name: "Frederick Hanson",
    position: "Founder & Classical Pianist",
    imageSrc: Frederick,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/frederick-hanson",
    },
  },
  {
    name: "Isabella Hanson",
    position: "Director",
    imageSrc: Isabella,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Joseph Akubilla",
    position: "Finance Manager",
    imageSrc: Joseph,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/joseph-akubilla/",
    },
  },
  {
    name: "Joseline Forson",
    position: "Administrative Manager",
    imageSrc: Joseline,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Elikplim Torgbenu",
    position: "Digital Marketer",
    imageSrc: Elikplim,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/elikplim-torgbenu-51b5b0160/",
    },
  },
  {
    name: "Kingsley David Gyekye",
    position: "Web Developer",
    imageSrc: Kingsley,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/in/kdgyekye",
    },
  },
  {
    name: "Jeremy Anokye-Poku",
    position: "Creative Designer",
    imageSrc: Jeremy,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/jeremy-anokye-poku-16968b214/",
    },
  },
  {
    name: "Samuel Quayson",
    position: "Education Supervisor",
    imageSrc: Samuel,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
];

export const MusicInstructors = [
  {
    name: "Frederick Hanson",
    position: "Piano Instructor",
    imageSrc: Frederick,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/frederick-hanson",
    },
  },
  {
    name: "Samuel Quayson",
    position: "Cello/Violin/Clarinet/Trumpet Instructor",
    imageSrc: Samuel,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "L'armour Ansah",
    position: "Piano Instructor",
    imageSrc: Larmour,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Ruth Amoo",
    position: "Piano Instructor",
    imageSrc: Ruth,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Bless Asher Adiku",
    position: "Violin Instructor",
    imageSrc: null,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Darell Jesse Mosses",
    position: "Viola Instructor",
    imageSrc: null,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
];

export const Perfomers = [
  {
    name: "Frederick Hanson",
    position: "Piaist",
    imageSrc: Frederick,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/frederick-hanson",
    },
  },
  {
    name: "Samuel Quayson",
    position: "Cellist",
    imageSrc: Samuel,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Dr. Heather Beem",
    position: "Violinist",
    imageSrc: Heather,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://www.linkedin.com/in/heather-beem/",
    },
  },
  {
    name: "L'armour Ansah",
    position: "Pianist",
    imageSrc: Larmour,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Bless Asher Adiku",
    position: "Violinist",
    imageSrc: null,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
  {
    name: "Darell Jesse Mosses",
    position: "Violist",
    imageSrc: null,
    socials: {
      twitter: "https://twitter.com/",
      facebook: "https://facebook.com/",
      linkedin: "https://linkedin.com/",
    },
  },
];

export const musicClassItems = [
  {
    id: "piano",
    name: "Piano",
    description: "The piano is a musical instrument played using a keyboard.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680540210/users/user-2807/46ff2aa04655284343c02ea16c701dab.png",
    imageUrl:
      "https://images.unsplash.com/photo-1520523839897-bd0b52f945a0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
    imageAlt: "Piano",
    info: {
      about:
        "Embark on a melodious voyage through our Piano Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  {
    id: "violin",
    name: "Violin",
    description:
      "The violin is the highest-pitched and smallest of the string instrument family played with a bow.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680540245/users/user-2807/59c6ba904e8a68a2d1167337a579e05a.png",
    imageUrl:
      "https://images.unsplash.com/photo-1603741209938-50fc882da856?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
    imageAlt: "Violin",
    info: {
      about:
        "Embark on a melodious voyage through our Violin Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  {
    id: "cello",
    name: "Cello",
    description: "The cello is used as a solo musical instrument.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680540272/users/user-2807/6b7bb8e8b9841cf7e423648e7bd6c727.png",
    imageUrl:
      "https://images.unsplash.com/photo-1603584915335-d612257071b0?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
    imageAlt: "Cello",
    info: {
      about:
        "Embark on a melodious voyage through our Cello Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  // {
  //   id: "viola",
  //   name: "Viola",
  //   description:
  //     "The viola is slightly larger than a violin with a lower and deeper sound. ",
  //   imageSrc:
  //     "https://res.cloudinary.com/estage/image/upload/v1680540245/users/user-2807/59c6ba904e8a68a2d1167337a579e05a.png",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1621368286550-f54551f39b91?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
  //   imageAlt: "Viola",
  //   info: {
  //     about:
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam. Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam.",
  //     whatYouWillLearn: [
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     ],
  //     requirements: [
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //       "Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.",
  //     ],
  //     price: 100,
  //     duration: "1 month",
  //     schedule: "Monday - Friday",
  //     time: "10:00am - 12:00pm",
  //     age: "5 - 15 years",
  //   },
  // },
  {
    id: "guitar",
    name: "Guitar",
    description:
      "The guitar is a string instrument played by plucking the string.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680540356/users/user-2807/3e6cffc28a830b042c5da518af907149.png",
    imageUrl:
      "https://images.unsplash.com/photo-1605020420620-20c943cc4669?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
    imageAlt: "Guitar",
    info: {
      about:
        "Embark on a melodious voyage through our Guitar Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  {
    id: "saxophone",
    name: "Saxophone",
    description:
      "The alto sax is a woodwind instrument played by blowing into it to make a reed vibrate.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680540384/users/user-2807/64db67032fde53d921f2f6ea68bf9df2.png",
    imageUrl:
      "https://plus.unsplash.com/premium_photo-1682614342415-eae6fe06af07?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
    imageAlt: "Saxophone",
    info: {
      about:
        "Embark on a melodious voyage through our Saxophone Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  // {
  //   id: "clarinet",
  //   name: "Clarinet",
  //   description:
  //     "The clarinet is a musical instrument belonging to the woodwind family.",
  //   imageSrc:
  //     "https://res.cloudinary.com/estage/image/upload/v1680540942/users/user-2807/c0e8edca0ebdf00a9b553c38de369602.png",
  //   imageAlt: "Clarinet",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1651232529331-758604de9a6a?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
  // },
  {
    id: "trumpet",
    name: "Trumpet",
    description:
      "The trumpet is a brass instrument used in classical and jazz ensembles.",
    imageSrc:
      "https://res.cloudinary.com/estage/image/upload/v1680541007/users/user-2807/dbbe40cb5fc539ab661f5925068819dd.png",
    imageAlt: "Trumpet",
    imageUrl:
      "https://images.unsplash.com/photo-1511192336575-5a79af67a629?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3132&q=80",
    info: {
      about:
        "Embark on a melodious voyage through our Trumpet Class, where the keys to unlocking your musical potential await. Our curriculum is meticulously designed with the following learning objectives:<ul><li>Appreciation of music by sight and ear</li><li>Foundation for musical development</li><li>Aural tests</li><li>Quizzes</li><li>Scaled & Arpeggios</li><li>Exercises</li></ul>",
      assessment: [
        "<strong>Recitals: </a~~strong> Display your evolving talents in 2-4 recitals where you'll perform selected pieces. These opportunities will not only boost your confidence but refine your stage presence.",
        "<strong>Sight-reading: </strong>Develop the ability to read and play music at sight, a fundamental skill for any pianist. You'll be challenged with unfamiliar pieces to enhance your sight-reading proficiency.",
        "<strong>Aural tests: </strong>Hone your musical ear through aural tests, where you'll identify melodies, harmonies, and musical nuances through listening.",
        "<strong>Quizzes: </strong>Periodic quizzes will test your knowledge of music theory, history, and the pieces you've been practicing",
        "<strong>Scaled & Arpeggios</strong>Mastery of scales and arpeggios is vital for enhancing your finger dexterity and technical prowess.",
        "<strong>Exercises: </strong>Regular exercises will fine-tune your playing technique and dexterity, ensuring your skills are honed to perfection.",
      ],
      opportunities: [
        "Participate in the “Music & Arts Talent Exhibition” concert.",
        "Join a vibrant music community.",
        "Collaborate with other students and musicians.",
      ],
      //price: 100,
      duration: "44-48 sessions",
      schedule: "Monday - Saturday",
      time: "1 hour per session",
      age: "All ages",
    },
  },
  // {
  //   id: "flute",
  //   name: "Flute",
  //   description:
  //     "The flute is a classical music instrument in the woodwind group. ",
  //   imageSrc:
  //     "https://res.cloudinary.com/estage/image/upload/v1680540942/users/user-2807/c0e8edca0ebdf00a9b553c38de369602.png",
  //   imageAlt: "Flute",
  //   imageUrl:
  //     "https://images.unsplash.com/photo-1638317037996-f86e745cf657?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2974&q=80",
  // },
];

export const features = [
  {
    title: "International Curriculum",
    Icon: FiBookOpen,
    description:
      "Our courses are patterned after the internationally acclaimed ABRSM standard recognised in over 93 countries, with over 600,000 candidates taking the ABRSM exams each year.",
  },
  {
    title: "Experienced Teachers",
    Icon: FiUserCheck,
    description:
      "Our experienced teachers create an engaging and friendly environment and are ready to deliver to your needs. You are never left to figure things out alone.",
  },
  {
    title: "Performance opportunities",
    Icon: FiMusic,
    description:
      "We create platforms to host mini-recitals and concerts for all our music students",
  },
  {
    title: "Vibrant Music Community",
    Icon: FiUsers,
    description:
      "We collaborate and connect with a wider network of music enthusiasts, music students from different schools, music educators, and musicians of all ages to create amazing experiences for our music.",
  },
  {
    title: "Programme Award",
    Icon: FiAward,
    description:
      "We offer a Certificate of Achievement for all our music students after they complete each music programme at any level.",
  },
];

export const levels = [
  { label: "Beginner Child (4-6yrs)", value: "beginner_child_1" },
  { label: "Beginner Child (7-17yrs)", value: "beginner_child_2" },
  { label: "Beginner Adult (18+)", value: "beginner_adult" },
  { label: "Intermediate", value: "intermediate" },
  //{ label: "Advanced", value: "advanced" },
];

export const programmes = [
  { label: "Early Years Music", value: "early_years_music" },
  { label: "Junior Conservatory", value: "junior_conservatory" },
  { label: "Adult Study", value: "adult_study" },
];

export const categories = [
  { label: "Classical", value: "classical" },
  { label: "Contemporary", value: "contemporary" },
];

export const grades = [
  { label: "Grade 1", value: "grade_1" },
  { label: "Grade 2", value: "grade_2" },
  { label: "Grade 3", value: "grade_3" },
  { label: "Grade 4", value: "grade_4" },
  { label: "Grade 5", value: "grade_5" },
  { label: "Grade 6", value: "grade_6" },
];

export const venues = [
  { label: "Accra", value: "accra" },
  { label: "Tema", value: "tema" },
];

export const classTypes = [
  { label: "Private", value: "private" },
  { label: "Group", value: "group" },
];

export const modesOfStudy = [
  { label: "Online", value: "online" },
  { label: "In-person", value: "in_person" },
];

type DonationCategories = {
  [key: string]: {
    name: string;
    description: string;
    url: string;
    overview: string;
    target: number;
    deadline: string;
    images: {
      src: string;
      width: number;
      height: number;
    }[];
    amountRaised: number;
  }[];
};

export const donationCategories: DonationCategories = {
  projects: [
    {
      name: "'MATE'",
      description:
        "Music & Arts Talents Exhibition is a yearly concert that create a platform to exhibit the creative musical and artistic talents within our music community.",
      url: "mate",
      overview:
        "Join us in showcasing burgeoning talent at our Music and Arts Talent Exhibition, where emerging artists and musicians display their gifts to the world. Your donation helps provide a stage for these bright new stars to shine, supporting a vibrant future for the arts.",
      target: 10000,
      deadline: "2023-12-31",
      images: [
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaA0eiLvg-uqSPp10BLJX1j8MbXqU8lSyrGb4ojZkQdwLnvLD5jjlGjs7q_9yLQR1J47KEzr6NX-v2mL4uZ9RbFeXuPc4w=s1600",
          width: 4,
          height: 4,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaDBQUaRn-YP-1d4m24s-g3b6go4nhIKLlH8hzTFDrCF0zkC2US1OF4AOv91Pcka5k7N70VKFDk1ib0E3tRNhPJng-sVPw=s1600",
          width: 4,
          height: 3,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaDwdEjxw9YNBLnssr2ZiXu5T_0MCpXz7kRHv5vbSmg1JyLOjxHfrzDaSbMlo5jMOINF5HSIraWoyh2dMst5oWrtgaIPvg=s1600",
          width: 4,
          height: 4,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaBMmAN6R8B2RoYQVGZp4885kO1YfBncGCPFlnJ16JBTxCimnr5IkiBG3JcV6aziZDNek4WdMd5L1ByZkuaGs_AFaN8Qqw=s1600",
          width: 4,
          height: 4,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaBs_SuPGKJd89BeBQksP3M4EiZATtVrtHPXnkhW0GvYIXuJM-8ZbJQYw0tulgAqv9TLPafmrosVd_ZofzodioRtAQdAEw=s1600",
          width: 4,
          height: 4,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaDiE5GrLVbhRehtoS4EmHeKZt2CBc_zK6HdBWYKZ-RPpzaP_fdjuBk149_rVmvXhXsm_9uO93daJYIyiIKAJ3GdIkpyMQ=s1600",
          width: 4,
          height: 4,
        },
        {
          src: "https://lh3.googleusercontent.com/drive-viewer/AK7aPaBttRQwV84Sjytr7vXslxAub6rqvOzdRcAmNU1CdybjX1Vwk0DJb7mnUjO_QxhjEJt7VDf_CrOhlVGuTQum24Fz6jZ-og=s1600",
          width: 4,
          height: 3,
        },
      ],
      amountRaised: 2000,
    },
    {
      name: "Allegro Music Studio",
      description:
        "A physical space (facility) where our Music Community can meet to learn, network, collaborate and share their music to the inspire the world.",
      url: "allegro-music-studio",
      overview:
        "Help us hit the right note by funding our Music Studio project — a creative sanctuary where aspiring artists can record, learn, and thrive. Your contribution lays down the tracks for the next generation of musical excellence.",
      target: 10000,
      deadline: "2023-12-31",
      images: [
        {
          src: "https://images.unsplash.com/photo-1598653222000-6b7b7a552625?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          width: 4,
          height: 4,
        },
        {
          src: "https://images.unsplash.com/photo-1598488035139-bdbb2231ce04?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          width: 4,
          height: 3,
        },
        {
          src: "https://images.unsplash.com/photo-1627407660893-fe01f60d44c4?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3087&q=80",
          width: 4,
          height: 4,
        },
        {
          src: "https://images.unsplash.com/photo-1621976975813-10e88ae6e450?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          width: 4,
          height: 4,
        },
      ],
      amountRaised: 1000,
    },
    {
      name: "'My Allegro' Music Hub",
      description:
        "A thriving and vibrant online Music Community (Music Hub) that connects musicians, students, teachers and music enthusiasts to innovate and collaborate.",
      url: "my-allegro",
      overview:
        "Contribute to the crescendo of our Online Music Hub, a digital platform dedicated to connecting musicians and learners worldwide. Your support enables us to harmonize education and innovation, making music accessible to all.",
      target: 50000,
      deadline: "2023-12-31",
      images: [
        {
          src: "https://images.unsplash.com/photo-1515704089429-fd06e6668458?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          width: 4,
          height: 4,
        },
      ],
      amountRaised: 500,
    },
  ],
  assets: [
    {
      name: "Musical Instruments",
      description: "Description here",
      images: [
        {
          src: "https://images.unsplash.com/photo-1564528183203-ec84690e06a8?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3174&q=80",
          width: 4,
          height: 4,
        },
      ],
      overview:
        "Every instrument is a voice waiting to be heard. Donate to help us provide quality musical instruments to under-resourced musicians, giving them the tools to play their stories and sing their truths to a world eager to listen.",
      target: 30000,
      deadline: "2023-12-31",
      url: "musical-instruments",
      amountRaised: 0,
    },
    {
      name: "Music Equipment",
      description: "Description Here",
      images: [
        {
          src: "https://images.unsplash.com/photo-1616586054875-9d4e6f3b416c?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=2970&q=80",
          width: 4,
          height: 4,
        },
      ],
      overview:
        "Amplify our impact by funding essential music equipment. From microphones to mixers, your donation ensures our performers have the cutting-edge technology to turn up the volume on their talents and reach audiences far and wide.",
      target: 10000,
      deadline: "2023-12-31",
      url: "music-equipment",
      amountRaised: 0,
    },
    {
      name: "Vehicle",
      description: "Description here",
      images: [
        {
          src: "https://images.unsplash.com/photo-1535655685871-dc8158ff167e?ixlib=rb-4.0.3&ixid=M3wxMjA3fDB8MHxwaG90by1wYWdlfHx8fGVufDB8fHx8fA%3D%3D&auto=format&fit=crop&w=3164&q=80",
          width: 4,
          height: 4,
        },
      ],
      overview:
        "Keep the rhythm moving! Your donations toward our vehicle will drive our mission forward, literally, as we transport instruments, equipment, and artists to various educational and performance venues. Be the fuel that powers our journey!",
      target: 20000,
      deadline: "2023-12-31",
      url: "vehicle",
      amountRaised: 0,
    },
  ],
};
