import React, { Suspense, lazy } from "react";
import {
  BrowserRouter as Router,
  Routes,
  Route,
  Outlet,
} from "react-router-dom";
import "./firebase.config";

import "./scss/main.scss";

import NotFound from "./pages/NotFound";

//Providers
import { AuthStatusProvider } from "./context/AuthContext";
import { GlobalProvider } from "./context/GlobalContext";
import { ModalProvider } from "./core-ui/Modals";

//Routes
import PrivateRoute from "./PrivateRoute";

// Toastify
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

// Page loader
import { WebPageLoader } from "./core-ui/Loaders";

//Layouts
import { MainLayout } from "./components/Layout";
import Dashboard from "./components/Dashboard/Dashboard";

//Navigation Routes
import { WebsiteNav, AdminNav } from "./navigation";
import { ThankYou } from "./pages/Donation/components";

//Auth
const Login = lazy(() => import("./pages/Auth/Login"));

//pages
const Instrument = lazy(() => import("./pages/Instrument"));
const Home = lazy(() => import("./pages/Admin/Home"));
const ProjectDonation = lazy(() => import("./pages/ProjectDonation"));

function App() {
  return (
    <div className="App h-screen">
      <AuthStatusProvider>
        <ModalProvider>
          <Router>
            <GlobalProvider>
              <Suspense fallback={<WebPageLoader />}>
                <Routes>
                  {/* Private Routes */}
                  <Route element={<PrivateRoute />}>
                    <Route path="/admin" element={<Dashboard />}>
                      <Route path="" element={<Home />} />
                      <Route element={<PrivateRoute />}>
                        {AdminNav.map((route, index) => (
                          <Route
                            key={index}
                            path={route.url}
                            element={route.component && <route.component />}
                          />
                        ))}
                      </Route>
                    </Route>
                  </Route>
                  {/* Auth Routes */}
                  <Route
                    element={
                      <PrivateRoute
                        shouldBeLoggedIn={false}
                        redirectTo="admin"
                        auth
                      />
                    }
                  >
                    <Route path="/login" element={<Login />} />
                  </Route>
                  {/* Public Website Pages */}
                  <Route
                    element={
                      <PrivateRoute shouldBeLoggedIn={false} redirectTo="" />
                    }
                  >
                    <Route path="/" element={<MainLayout />}>
                      {WebsiteNav.map((route, index) => (
                        <Route
                          key={index}
                          path={route.url}
                          element={route.component && <route.component />}
                        />
                      ))}
                      <Route
                        path="/learn/:instrumentId"
                        element={<Instrument />}
                      />
                      <Route
                        path="/donate/thank-you"
                        element={<ThankYou />}
                        />
                      <Route
                        path="/donate/:donationId/:projectId"
                        element={<ProjectDonation />}
                        />
                    </Route>
                  </Route>

                  {/* 404 Not Found */}
                  <Route path="*" element={<NotFound />} />
                </Routes>
              </Suspense>
            </GlobalProvider>
          </Router>
        </ModalProvider>
      </AuthStatusProvider>
      <ToastContainer position={toast.POSITION.TOP_CENTER} />
    </div>
  );
}

export default App;
