import { ChevronRightIcon } from "@heroicons/react/24/outline";
import { useRef } from "react";
import useIntersectionObserver from "../../../components/hooks/useIntersectionObserver";
import { donationCategories } from "../../../inc/data";
import { useNavigate } from "react-router";

const OtherAssets = () => {
    const sectionRef1 = useRef<HTMLDivElement>(null);
    const sectionRef2 = useRef<HTMLDivElement>(null);
    useIntersectionObserver([
      {
        targetRef: sectionRef1,
        className: "fade-in-top",
        options: { threshold: 0.3 },
      },
      {
        targetRef: sectionRef2,
        className: "fade-in-bottom",
        options: { threshold: 0.3 },
      }
    ]);

    const navigate = useNavigate();
    const { assets } = donationCategories
  return (
    <div className="bg-gray-100 py-24">
      <div className="mx-auto max-w-7xl px-6 lg:px-8">
        <div className="mx-auto lg:mx-0 shadow w-full bg-white p-5 opacity-0" ref={sectionRef1}>
          <h2 className="text-2xl font-bold tracking-tight text-gray-900 sm:text-4xl">
            Other Assets
          </h2>
          <p className="mt-6 text-lg leading-8 text-gray-600">
            Browse through the diverse assets that underpin our mission, each playing a pivotal role in driving our initiatives forward—your support for these assets fuels our collective success.
          </p>
        </div>
        <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mt-12 opacity-0" ref={sectionRef2}>
          {assets.map((project) => (
            <div className="shadow bg-white p-5 rounded-lg">
              <div className="flex justify-center">
                <img
                  src={project.images[0].src}
                  alt="Project visual"
                  className="w-full h-48 object-cover object-center rounded-md"
                />
              </div>
              <h3 className="text-xl font-bold tracking-tight text-gray-900 sm:text-2xl mt-5 flex justify-center">
                {project.name}
              </h3>
              <p className="mt-4 text-base leading-6 text-gray-600 flex justify-center text-center">
                {project.description}
              </p>
              <div className="flex justify-center">
                <button
                  type="button"
                  className="my-5 inline-flex items-center gap-x-1.5 rounded-md bg-primary px-3 py-2 text-base font-semibold text-white shadow-sm hover:bg-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-primary"
                  onClick={() => navigate(`/donate/assets/${project.url}`)}
                >
                  Read more
                  <ChevronRightIcon
                    className=" h-5 w-5"
                    aria-hidden="true"
                  />
                </button>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default OtherAssets;
