import React from "react";
import TopNav from "../TopNav";
import Footer from "../Footer";
import { Outlet } from "react-router-dom";
import ScrollToTop from "../../utils/ScrollToTop";

interface ILayoutProps {
  children?: React.ReactNode;
}

const MainLayout: React.FC<ILayoutProps> = ({ children }) => {
  return (
    <div className="bg-gray-50">
      <TopNav />
      <ScrollToTop />
      <Outlet />
      <Footer />
    </div>
  );
};

export default MainLayout;
