import { useState, useLayoutEffect } from "react";
import { Dialog } from "@headlessui/react";
import { Bars3Icon, XMarkIcon } from "@heroicons/react/24/outline";
import { WebsiteNav } from "../../navigation";
import { Link } from "react-router-dom";
import AllegroLogo from "../../assets/images/allegro-logo.png";
import { useLocation } from "react-router-dom";

export default function TopNav() {
  const [mobileMenuOpen, setMobileMenuOpen] = useState(false);

  // know your scroll position
  const [initialScrollPositionMoved, setInitialScrollPositionMoved] =
    useState<boolean>(false);
  useLayoutEffect(() => {
    window.onscroll = function () {
      if (window.pageYOffset !== 0) {
        setInitialScrollPositionMoved(true);
      } else setInitialScrollPositionMoved(false);
    };
  }, []);

  // know your current route
  const location = useLocation();
  const initialPath = location.pathname.split("/")[1];

  return (
    <header
      className={`absolute top-0 z-50 w-full ${
        initialScrollPositionMoved ? "bg-gray-950 sticky" : ""
      }`}
    >
      <nav
        className="mx-auto flex max-w-7xl items-center justify-between p-4 lg:px-8"
        aria-label="Global"
      >
        <div className="flex lg:flex-1 -pl-4">
          <Link to="/" className="-m-1.5 p-1.5">
            <span className="sr-only">Allegro Classisc Logo</span>
            <img className="h-16 w-32" src={AllegroLogo} alt="Allegro Logo" />
          </Link>
        </div>
        <div className="flex lg:hidden">
          <button
            type="button"
            className="-m-2.5 inline-flex items-center justify-center rounded-md p-2.5 text-gray-400"
            onClick={() => setMobileMenuOpen(true)}
          >
            <span className="sr-only">Open main menu</span>
            <Bars3Icon className="h-6 w-6" aria-hidden="true" />
          </button>
        </div>
        <div className="hidden lg:flex lg:gap-x-12">
          {WebsiteNav?.map((item, itemIdx) => {
            if (!item?.isNotLink) {
              return (
                <Link
                  key={itemIdx}
                  to={item.url}
                  className={`text-sm font-semibold leading-6 hover:border-b-2 hover:border-primary border-transparent border-b-2 transition-colors duration-200 pb-2 ${
                    "/" + initialPath === item.url
                      ? "border-primary text-primary"
                      : "text-white"
                  }`}
                >
                  {item.title}
                </Link>
              );
            }
            return null;
          })}
        </div>
        <div className="hidden lg:flex lg:flex-1 lg:justify-end space-x-4">
          {/* <Link
            to="#"
            className="text-sm font-semibold leading-6 hover:bg-primary-hover hover:text-gray-100 bg-primary text-white px-3 py-2 rounded-lg"
          >
            My Allegro
          </Link> */}
          {/* <Link
            to="/login"
            className="text-sm font-semibold leading-6 hover:bg-primary hover:text-white bg-white text-primary px-3 py-2 rounded-lg"
          >
            Log in <span aria-hidden="true">&rarr;</span>
          </Link> */}
        </div>
      </nav>
      <Dialog
        as="div"
        className="lg:hidden"
        open={mobileMenuOpen}
        onClose={setMobileMenuOpen}
      >
        <div className="fixed inset-0 z-10" />
        <Dialog.Panel className="fixed inset-y-0 right-0 z-10 w-full overflow-y-auto bg-black px-6 py-6 sm:max-w-sm sm:ring-1 sm:ring-white/10">
          <div className="flex items-center justify-between">
            <Link to="/" className="-m-1.5 p-1.5">
              <span className="sr-only">Allegro Classisc Logo</span>
              <img
                className="h-14 w-auto"
                src={AllegroLogo}
                alt="Allegro Logo"
              />
            </Link>
            <button
              type="button"
              className="-m-2.5 rounded-md p-2.5 text-gray-400"
              onClick={() => setMobileMenuOpen(false)}
            >
              <span className="sr-only">Close menu</span>
              <XMarkIcon className="h-6 w-6" aria-hidden="true" />
            </button>
          </div>
          <div className="mt-6 flow-root">
            <div className="-my-6 divide-y divide-gray-500/25">
              <div className="space-y-2 py-6">
                {WebsiteNav.map((item, itemIdx) => {
                  if (!item?.isNotLink) {
                    return (
                      <Link
                        key={itemIdx}
                        to={item.url}
                        className={`-mx-3 block rounded-lg px-3 py-2 text-base font-semibold leading-7 hover:bg-gray-800 ${
                          "/" + initialPath === item.url
                            ? "text-primary"
                            : "text-white"
                        }`}
                        onClick={() => setMobileMenuOpen(false)}
                      >
                        {item.title}
                      </Link>
                    );
                  }
                  return null; // Add this line
                })}
              </div>
              <div className="py-6">
                {/* <Link
                  to="#"
                  className="text-sm font-semibold leading-6 hover:bg-primary-hover hover:text-gray-100 bg-primary text-white px-3 py-2 rounded-lg"
                >
                  My Allegro
                </Link> */}
                {/* <Link
                  to="/login"
                  className="-mx-3 block rounded-lg px-3 py-2.5 text-base font-semibold leading-7 hover:bg-gray-800 bg-primary text-white"
                >
                  Log in
                </Link> */}
              </div>
            </div>
          </div>
        </Dialog.Panel>
      </Dialog>
    </header>
  );
}
